export default class Lang {
    constructor() {
        this.en = {
            //items-form sort list
            sort_term_a_z: 'Top A-Z',
            sort_term_z_a: 'Top Z-A',
            sort_definition_a_z: 'Bottom A-Z',
            sort_definition_z_a: 'Bottom Z-A',

            // items-form tooltips
            'items-import': 'Import Word List',
            'items-clear': 'Clear Words',
            'items-flip': 'Flip Terms & Definitions',

            //global terms
            term: 'Term',
            definition: 'Definition',

            //worksheet-types
            matching: 'Matching',
            multiple_choice: 'Multiple Choice',
            fill_in_the_blank: 'Fill in the Blank',
            open_response: 'Open Response',
            handwriting: 'Handwriting',
            word_scramble: 'Word Scramble',
            bingo: 'Bingo Cards',
            flashcards: 'Flashcards',
        }
    }

    get(string) {
        let language = 'en'
        if (typeof this[language][string] != 'undefined') {
            return this[language][string]
        } else {
            return '[' + string + ' does not exist]'
        }
    }

    set(key, value) {
        this[key] = value
    }
}
